/**
 * @license
 *
 * Font Family: Switzer
 * Designed by: Jérémie Hornus
 * URL: https://www.fontshare.com/fonts/switzer
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Switzer Variable(Variable font)
 * Switzer Variable Italic(Variable font)
 * Switzer Thin
 * Switzer Thin Italic
 * Switzer Extralight
 * Switzer Extralight Italic
 * Switzer Light
 * Switzer Light Italic
 * Switzer Regular
 * Switzer Italic
 * Switzer Medium
 * Switzer Medium Italic
 * Switzer Semibold
 * Switzer Semibold Italic
 * Switzer Bold
 * Switzer Bold Italic
 * Switzer Extrabold
 * Switzer Extrabold Italic
 * Switzer Black
 * Switzer Black Italic
 *
*/


/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: 'wght' 400.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'Switzer-Variable';
  src: url('./Switzer-Variable.woff2') format('woff2'),
  url('./Switzer-Variable.woff') format('woff'),
  url('./Switzer-Variable.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}


/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: 'wght' 400.0;
*
* available axes:

* 'wght' (range from 100.0 to 900.0)

*/

@font-face {
  font-family: 'Switzer-VariableItalic';
  src: url('./Switzer-VariableItalic.woff2') format('woff2'),
  url('./Switzer-VariableItalic.woff') format('woff'),
  url('./Switzer-VariableItalic.ttf') format('truetype');
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Thin';
  src: url('./Switzer-Thin.woff2') format('woff2'),
  url('./Switzer-Thin.woff') format('woff'),
  url('./Switzer-Thin.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-ThinItalic';
  src: url('./Switzer-ThinItalic.woff2') format('woff2'),
  url('./Switzer-ThinItalic.woff') format('woff'),
  url('./Switzer-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Extralight';
  src: url('./Switzer-Extralight.woff2') format('woff2'),
  url('./Switzer-Extralight.woff') format('woff'),
  url('./Switzer-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-ExtralightItalic';
  src: url('./Switzer-ExtralightItalic.woff2') format('woff2'),
  url('./Switzer-ExtralightItalic.woff') format('woff'),
  url('./Switzer-ExtralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Light';
  src: url('./Switzer-Light.woff2') format('woff2'),
  url('./Switzer-Light.woff') format('woff'),
  url('./Switzer-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-LightItalic';
  src: url('./Switzer-LightItalic.woff2') format('woff2'),
  url('./Switzer-LightItalic.woff') format('woff'),
  url('./Switzer-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Regular';
  src: url('./Switzer-Regular.woff2') format('woff2'),
  url('./Switzer-Regular.woff') format('woff'),
  url('./Switzer-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-Italic';
  src: url('./Switzer-Italic.woff2') format('woff2'),
  url('./Switzer-Italic.woff') format('woff'),
  url('./Switzer-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Medium';
  src: url('./Switzer-Medium.woff2') format('woff2'),
  url('./Switzer-Medium.woff') format('woff'),
  url('./Switzer-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-MediumItalic';
  src: url('./Switzer-MediumItalic.woff2') format('woff2'),
  url('./Switzer-MediumItalic.woff') format('woff'),
  url('./Switzer-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Semibold';
  src: url('./Switzer-Semibold.woff2') format('woff2'),
  url('./Switzer-Semibold.woff') format('woff'),
  url('./Switzer-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-SemiboldItalic';
  src: url('./Switzer-SemiboldItalic.woff2') format('woff2'),
  url('./Switzer-SemiboldItalic.woff') format('woff'),
  url('./Switzer-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Bold';
  src: url('./Switzer-Bold.woff2') format('woff2'),
  url('./Switzer-Bold.woff') format('woff'),
  url('./Switzer-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-BoldItalic';
  src: url('./Switzer-BoldItalic.woff2') format('woff2'),
  url('./Switzer-BoldItalic.woff') format('woff'),
  url('./Switzer-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Extrabold';
  src: url('./Switzer-Extrabold.woff2') format('woff2'),
  url('./Switzer-Extrabold.woff') format('woff'),
  url('./Switzer-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-ExtraboldItalic';
  src: url('./Switzer-ExtraboldItalic.woff2') format('woff2'),
  url('./Switzer-ExtraboldItalic.woff') format('woff'),
  url('./Switzer-ExtraboldItalic.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Switzer-Black';
  src: url('./Switzer-Black.woff2') format('woff2'),
  url('./Switzer-Black.woff') format('woff'),
  url('./Switzer-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Switzer-BlackItalic';
  src: url('./Switzer-BlackItalic.woff2') format('woff2'),
  url('./Switzer-BlackItalic.woff') format('woff'),
  url('./Switzer-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

/* Avenir Font Faces */
@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-BlackOblique.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-Book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-BookOblique.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-Heavy.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-HeavyOblique.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-LightOblique.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-MediumOblique.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-Oblique.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirLTStd';
  src: url('./AvenirLTStd-Roman.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Epidemic';
  src: url('./epidemic.otf') format('opentype'),
  url('./epidemic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Retreat';
  src: url('./Retreat.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-black-italic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-bold-italic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-book-italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-book.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-extrablack-italic.otf') format('opentype');
  font-weight: 950;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-extrablack.otf') format('opentype');
  font-weight: 950;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-extrabold-italic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-extrabold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-extralight-italic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-extralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-hairline-italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-hairline.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-italic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-light-italic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-medium-italic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-thin-italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-ultralight-italic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Rationell';
  src: url('./Fontspring-DEMO-rationell-ultralight.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}
