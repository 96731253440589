:root {
  --stroke-color-light: white;
  --stroke-color-dark: black;
}

[data-theme="light"] {
  --stroke-color: var(--stroke-color-light);
}

[data-theme="dark"] {
  --stroke-color: var(--stroke-color-dark);
}


/* index.css */
:root {
  --chart-text-color: theme('colors.chartTextLight');
  --chart-grid-color: theme('colors.chartGridLight');
}

.dark {
  --chart-text-color: theme('colors.chartTextDark');
  --chart-grid-color: theme('colors.chartGridDark');
}
